import React from "react";
import { Box, Container, Stack } from "@mui/material";
import { HeadingTagComponent } from "../../../atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../atoms/Headings/HeadingRedComponent";
import { StudentStoriesSliderComponent } from "../../../atoms/Sliders/StudentStoiesSliderComponent/StudentStoriesSliderComponent";
import {
  ANUTHTHARA,
  LILAN,
  MAHESHI_RANDIKA,
  NALIN,
  NO_USER_IMAGE,
  PIUMI,
  VIHANGA_CANADA,
} from "../../../../assets/Images";

export const StudentStories = () => {
  const stories = [
    {
      image: VIHANGA_CANADA,
      review:
        "I had an incredible experience with OASES educational consultants! They made my visa application process seamless and stress-free. The entire team was exceptionally helpful and supportive throughout.",
      first_name: "Vihanga",
      last_name: "Samadhi",
      country: "Canada",
    },
    // {
    //   image: PIUMI,
    //   review: "Review will be updated soon",
    //   first_name: "Piumi",
    //   last_name: "Dilruwan",
    //   country: "",
    // },
    // {
    //   image: MAHESHI_RANDIKA,
    //   review: "Review will be updated soon",
    //   first_name: "Maheshi",
    //   last_name: "Randika",
    //   country: "",
    // },
    {
      image: LILAN,
      review:
        "I want to express my gratitude for the excellent services provided by OASES. Their professionalism and attention to detail truly made a positive difference in my experience. I especially want to thank Wathsala for their exceptional support throughout the process. they were incredibly helpful, and responsive and ensured that every service aspect was handled efficiently and with care.",
      first_name: "Lilan",
      last_name: "Balasooriya",
      country: "Canada",
    },
    // {
    //   image: ANUTHTHARA,
    //   review: "Review will be updated soon",
    //   first_name: "Anuththara",
    //   last_name: "",
    //   country: "",
    // },

    {
      image: NALIN,
      review:
        "This is one of the best visa/education consultants in the country. Friendly staff, and untiring support extended is commendable. The manager Ms. Wathsala is creating the environment pleasing to visitors coming under her umbrella. OASES keep going !!!",
      first_name: "Nalin",
      last_name: "Dissanayake",
      country: "France",
    },
  ];
  return (
    <Container maxWidth="lg">
      <Stack spacing={2}>
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <HeadingTagComponent text="Student Stories" />
          <Stack alignItems={"center"}>
            <HeadingBlueComponent variant="h4" text="What they say about" />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "baseline",
                columnGap: 1,
              }}
            >
              <HeadingRedComponent variant="h4" text="OASES" />
              <HeadingBlueComponent variant="h4" text="Education" />
            </Box>
          </Stack>
        </Stack>
        <Box>
          <StudentStoriesSliderComponent stories={stories} />
        </Box>
      </Stack>
    </Container>
  );
};
