import React, { Fragment, useEffect, useState } from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Avatar, Box, Card, Divider, Typography } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { FooterContainer, NavbarComponent } from "../../components/organisms";
import { MAL_FLAG, SL_FLAG, US_FLAG, WHATSAPP_ICON } from "../../assets/Images";
import {
  ANUPAMA_BALASOORIYA,
  HIRU_WANIGASOORIYA,
  WATHSALA_KARUNARATHNE,
} from "../../assets/Images/StaffPhotos";
import _ from "lodash";
import { useNavbarContext } from "../../core/context/NavbarContext";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { LazyLoadImageComponent } from "../../components/atoms/LazyLoad/LazyLoadImageComponent";
export const WebsiteLayout = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { isHome, getCurrentPath } = useNavbarContext();
  const location = useLocation();

  useEffect(() => {
    getCurrentPath(location.pathname);
  }, [location.pathname, getCurrentPath]);

  const [isClickWhatsApp, setIsClickWhatsApp] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const trigger = useScrollTrigger({
    threshold: 360,
    disableHysteresis: true,
  });

  useEffect(() => {
    setIsSticky(trigger);
  }, [trigger]);

  const contacts = [
    {
      img: WATHSALA_KARUNARATHNE,
      name: "Wathsala - Sri Lanka",
      contactNo: "+94772257575",
      flag : SL_FLAG
    },
    {
      img: ANUPAMA_BALASOORIYA,
      name: "Anupama - Singapore",
      contactNo: "+6581574792",
      flag : MAL_FLAG
    },
    {
      img: HIRU_WANIGASOORIYA,
      name: "Hiruni - United States",
      contactNo: "+15626430665",
      flag : US_FLAG
    },
  ];

  const handleClickWhatsApp = () => {
    if (isClickWhatsApp) {
      setIsClickWhatsApp(false);
      setTimeout(() => setIsVisible(false), 300);
    } else {
      setIsVisible(true);
      setTimeout(() => setIsClickWhatsApp(true), 0);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      {isHome ? (
        <>
          {!isSticky ? (
            <Box sx={{ position: "absolute", height: "100%", width: "100%" }}>
              <NavbarComponent isSticky={isSticky} />
            </Box>
          ) : (
            <NavbarComponent isSticky={isSticky} />
          )}
        </>
      ) : (
        <NavbarComponent isSticky={true} />
      )}

      <Box>
        <Outlet />
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 50,
          right: 30,
          zIndex: 100,
          cursor: "pointer",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: -170,
              right: 10,
              borderRadius: 10,
              display: isVisible ? "block" : "none",
              opacity: isClickWhatsApp ? 1 : 0,
              transform: isClickWhatsApp ? "scale(1)" : "scale(0.8)",
              transition: "opacity 0.3s ease, transform 0.3s ease",
            }}
          >
            <Box sx={{ position: "relative", color: "red" }}>
              <Box
                sx={{ position: "absolute", top: 5, right: 5 }}
                onClick={handleClickWhatsApp}
              >
                <HighlightOffSharpIcon
                  fontSize="small"
                  sx={{
                    bgcolor: "#fff",
                    borderRadius: 4,
                    "&:hover": {
                      transform: {
                        xl: "scale(1.5)",
                        lg: "scale(1.5)",
                        md: "scale(1.5)",
                        sm: "scale(1)",
                        xs: "scale(1)",
                      },
                      transition: "opacity 0.3s ease, transform 0.3s ease",
                    },
                    transform: "scale(1)",
                    transition: "opacity 0.3s ease, transform 0.3s ease",
                  }}
                />
              </Box>
            </Box>
            <Card
              sx={{ width: 250, p: 0.5, borderRadius: 5, bgcolor: "#ffffff" }}
            >
              {_.map(contacts, (contact, index) => {
                return (
                  <Fragment key={index + 1}>
                    <a
                      href={`https://wa.me/${_.replace(
                        _.get(contact, "contactNo", ""),
                        /[\s+]/g,
                        ""
                      )}`}
                      className="whatsapp_float"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <Box
                        sx={{
                          "&:hover": {
                            bgcolor: "#052343",
                            borderRadius: 10,
                            color: "#fff",
                          },
                          color: "#052343",
                          p: 0.8,
                          mt: 0.5,
                          mb: 0.5,
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                      
                        <Box sx={{ position: "relative",top:5, display:'flex', alignItems:'center'}}>
                          <LazyLoadImageComponent
                           src={_.get(contact, "img", {})}
                          alt="whatapp-logo1"
                            styles={{ width: 44, height: 44 , objectFit:'cover', borderRadius:50 }}
                          />
                          <Box sx={{position:'absolute', right:-3, bottom:-2}}>
                              <LazyLoadImageComponent  src={_.get(contact, "flag", {})}
                          alt="whatapp-fflag" styles={{ width: 20,
                            height: 20,}}/>
                            </Box>
                        </Box>

                        <Box>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontFamily: "OakSans" }}
                          >
                            {_.get(contact, "name", "")}
                          </Typography>
                        </Box>
                      
                      </Box>
                    </a>

                    <Divider
                      sx={{
                        bgcolor: "#FD5B2C",
                        display: index == 2 ? "none" : "block",
                      }}
                    />
                  </Fragment>
                );
              })}
            </Card>
          </Box>
          <LazyLoadImageComponent 
             src={WHATSAPP_ICON}
            alt="whatapp-logo"
            styles={{
              position:'absolute',
              right:4,
              bottom:-50  ,
              width: 35,
              height: 35,
              "&:hover": {
                transform: {
                  xl: "scale(1.5)",
                  lg: "scale(1.5)",
                  md: "scale(1.5)",
                  sm: "scale(1)",
                  xs: "scale(1)",
                },
                transition: "opacity 0.3s ease, transform 0.3s ease",
              },
              transform: "scale(1)",
              transition: "opacity 0.3s ease, transform 0.3s ease",
            }}
            onClick={handleClickWhatsApp}
          />
         
        </Box>
      </Box>

      <Box sx={{ position: "relative", bottom: 0 }}>
        <FooterContainer />
      </Box>
    </Box>
  );
};
