import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import _ from "lodash";
import "./OurTeamStyle.scss";
import {
  ANUPAMA_BALASOORIYA,
  ARUNI_KARUNARATHNE,
  ATHAPATHTHU,
  CHARUNI_GUNATHILAKE,
  DAMITH_MD,
  DEWDUNI,
  EDMON_WIMALARATHNE,
  GAWESHI,
  GAYAN_WICKRAMARATHENE,
  HIRU_WANIGASOORIYA,
  IRESHA,
  JALIYA_WELIWATHTHA,
  JOHN,
  LAKMINI_PIYATISSA,
  MADU_HATHARASIGNHE,
  MINOLI_DANASOORIYA,
  NIPUNI,
  PARTHANA_DHARMASIRI,
  PRASHASTHI,
  SAJINI,
  SHESHANI_WIJESIGNHE,
  TAKSHILA,
  THILINI,
  WATHSALA_KARUNARATHNE,
} from "../../../../assets/Images/StaffPhotos";
import { LazyLoadImageComponent } from "../../../../components/atoms/LazyLoad/LazyLoadImageComponent";

export const OurTeamSection = () => {
  const members = [
    {
      image: DAMITH_MD,
      firstName: "Damith  ",
      lastName: "Wickramasinghe",
      position: "Managing Director",
    },
    {
      image: JALIYA_WELIWATHTHA,
      firstName: "Jaliya",
      lastName: "Weliwatta",
      position: "International Business Development",
    },
    {
      image: WATHSALA_KARUNARATHNE,
      firstName: "Wathsala",
      lastName: "Karunadhipathi",
      position: "Manager - Operations",
    },
    {
      image: ARUNI_KARUNARATHNE,
      firstName: "Aruni",
      lastName: "Karunarathne",
      position: "Managing - Visa Processing",
    },
    {
      image: MINOLI_DANASOORIYA,
      firstName: "Minoli",
      lastName: "Danansooriya",
      position: "Manager - Admissions",
    },
    {
      image: MADU_HATHARASIGNHE,
      firstName: "Madu",
      lastName: "Hatharasinghe",
      position: "International Business Development Manager",
    },
    {
      image: GAYAN_WICKRAMARATHENE,
      firstName: "Gayan",
      lastName: "Wickramasinghe",
      position: "Manager – Colombo Branch",
    },
    {
      image: SHESHANI_WIJESIGNHE,
      firstName: "Seshani ",
      lastName: "Wijesinghe",
      position: "Assistant Manager – Visa Processing",
    },
    {
      image: LAKMINI_PIYATISSA,
      firstName: "Lakmini",
      lastName: "Piyathissa",
      position: "Accountant",
    },
    {
      image: SAJINI,
      firstName: "Sajini",
      lastName: "Fernando",
      position: "Senior Student Counsellor",
    },
    {
      image: IRESHA,
      firstName: "Iresha",
      lastName: "Wijerathne",
      position: "Senior Student Counsellor",
    },
    {
      image: HIRU_WANIGASOORIYA,
      firstName: "Hiruni ",
      lastName: "Wanigasuriya",
      position: "Senior Student Counsellor",
    },
    {
      image: ANUPAMA_BALASOORIYA,
      firstName: "Anupama ",
      lastName: "Balasooriya",
      position: "Senior Student Counsellor",
    },
    {
      image: CHARUNI_GUNATHILAKE,
      firstName: "Charuni ",
      lastName: "Gunathilake",
      position: "Senior Student Counsellor",
    },
    {
      image: JOHN,
      firstName: "Dean ",
      lastName: "John",
      position: "Senior Student Counsellor",
    },
    {
      image: NIPUNI,
      firstName: "Nipuni  ",
      lastName: "Wijekoon",
      position: "Senior Student Counsellor",
    },
    {
      image: THILINI,
      firstName: "Thilini ",
      lastName: "Wanniarachchi ",
      position: "Senior Student Counsellor",
    },
    {
      image: DEWDUNI,
      firstName: "Dewduni",
      lastName: "Swarnanayaka",
      position: "Senior Student Counsellor",
    },
    {
      image: GAWESHI,
      firstName: "Gaveshi",
      lastName: "Rajapaksha",
      position: "Senior Student Counsellor",
    },
    {
      image: PRASHASTHI,
      firstName: "Prashasthi ",
      lastName: "Wijesooriya",
      position: "Senior Student Counsellor",
    },
    {
      image: ATHAPATHTHU,
      firstName: "Rukman ",
      lastName: "Athapaththu",
      position: "Administration Officer – Kurunegala Branch",
    },
    {
      image: TAKSHILA,
      firstName: "Thakshila ",
      lastName: "Buwaneka",
      position: "Administration Officer – Kurunegala Branch",
    },
    {
      image: EDMON_WIMALARATHNE,
      firstName: "Edmon  ",
      lastName: "Wimalarathne",
      position: "Administration Officer – Colombo Branch",
    },
  ];

  return (
    <Container maxWidth="lg">
      <Stack spacing={2}>
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <HeadingTagComponent text="Members" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              columnGap: 1,
            }}
          >
            <HeadingRedComponent variant="h4" text="Our Expert " />
            <HeadingBlueComponent variant="h4" text="Team" />
          </Box>
        </Stack>
        <Swiper
          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true, el: ".custom-pagination" }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
          className="custom-swiper"
        >
          {_.map(members, (member, index) => {
            return (
              <SwiperSlide key={index + 1}>
                <Card sx={{ boxShadow: "none", width: "100%", height: "100%" }}>
                  <LazyLoadImageComponent
                    src={_.get(member, "image", "")}
                    alt={`${member.firstName} ${member.lastName}`}
                    styles={{
                      height:340,
                      width:280,
                      objectFit:'cover'
                    }}
                  />
                
                  <CardContent>
                    <Stack spacing={0} sx={{ alignItems: "center" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontFamily: "OakSans", color: "#01203D" }}
                      >{`${_.get(member, "firstName", "")} ${_.get(
                        member,
                        "lastName",
                        ""
                      )}`}</Typography>
                      <Typography
                        variant="caption"
                        sx={{ fontFamily: "OakSans", color: "#305874" }}
                      >
                        {_.get(member, "position", "")}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </SwiperSlide>
            );
          })}
          <div className="custom-pagination" />
        </Swiper>
      </Stack>
    </Container>
  );
};
