import React from "react";
import { Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { HeadingTagComponent } from "../../../../components/atoms/Tags/HeadingTagComponent";
import { INTERNATIONAL_OFFICES_IMAGE } from "../../../../assets/Images";
import { TextbuttonOnhoverUnderline } from "../../../../components/atoms/Buttons/TextbuttonOnhoverUnderline";
import { BranchDetailsContentComponent } from "../../../../components/atoms/BranchDetails/BranchDetailsContentComponent";
import { HeadingBlueComponent } from "../../../../components/atoms/Headings/HeadingBlueComponent";
import { HeadingRedComponent } from "../../../../components/atoms/Headings/HeadingRedComponent";
import { LazyLoadImageComponent } from "../../../../components/atoms/LazyLoad/LazyLoadImageComponent";

export const InternationalOffices = () => {
  return (
    <Container maxWidth={"xl"} className="international-offices-container">
      <Grid
        container
        sx={{
          paddingInline: 2,
          flexDirection: "column",
          rowGap: 10,
        }}
      >
        <Grid item xs={12}>
          <Grid container rowGap={2} justifyContent={"space-between"}>
            <Grid item xl={4} lg={4} md={6} xs={12}>
              <Stack spacing={4}>
                <Stack spacing={2}>
                  <HeadingTagComponent text="International Offices" />
                  <Stack>
                    <Grid container sx={{ alignItems: "baseline", gap: 1 }}>
                      <Grid item>
                        <HeadingBlueComponent variant="h4" text="Explore Our" />
                      </Grid>
                      <Grid item>
                        <HeadingRedComponent variant="h4" text="Worldwide" />
                      </Grid>
                    </Grid>
                    <HeadingBlueComponent variant="h4" text="Branches" />
                  </Stack>
                </Stack>

                <Typography
                  variant="subtitle1"
                  sx={{ fontFamily: "OakSans", color: "#01203D" }}
                >
                  Learn about our offices in various countries dedicated to
                  providing exceptional educational consultation services.
                </Typography>

                <TextbuttonOnhoverUnderline text="View more" />
              </Stack>
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <LazyLoadImageComponent
                src={INTERNATIONAL_OFFICES_IMAGE}
                alt={"international-img"}
                styles={{ width: "100%", height: 285, objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"space-between"} rowGap={2}>
            <Grid item xl={2} lg={2} md={2}>
              <BranchDetailsContentComponent
                country={"Sri Lanka"}
                email={"info@oasesedu.com"}
                address={
                  "No: 101, 2nd Floor, Colombo Road, Kurunegala, Sri Lanka. 60000"
                }
                contact={"+94 (77) 225 7575 "}
              />
            </Grid>
            <Divider
              orientation={"vertical"}
              sx={{
                bgcolor: "#FD5B2C",
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "none",
                  xs: "none",
                },
              }}
              flexItem
            />
            <Grid item xl={2} lg={2} md={2}>
              <BranchDetailsContentComponent
                country={"Canada"}
                email={"support@oasesglobal.net"}
                address={
                  "117,Emerald Street South,Unit 11,Hamilton,8N 2V4, Ontario"
                }
                contact={"+1 (43) 7981 5877 "}
              />
            </Grid>
            <Divider
              orientation={"vertical"}
              sx={{
                bgcolor: "#FD5B2C",
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "none",
                  xs: "none",
                },
              }}
              flexItem
            />
            <Grid item xl={2} lg={2} md={2}>
              <BranchDetailsContentComponent
                country={"Singapore"}
                email={"support@oasesglobal.net"}
                address={
                  "OASES Global Pte Ltd, 68 Circular Road, #02-01, Singapore (049422)"
                }
                contact={"+65 (9) 069 4654"}
              />
            </Grid>
            <Divider
              orientation={"vertical"}
              sx={{
                bgcolor: "#FD5B2C",
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "none",
                  xs: "none",
                },
              }}
              flexItem
            />
            <Grid item xl={2} lg={2} md={2}>
              <BranchDetailsContentComponent
                country={"USA"}
                email={"support@oasesglobal.net"}
                address={
                  "OASES Global LLC, 1401 21st Street, STE R Sacramento, CA 95811"
                }
                contact={"+1 (916) 283-7606"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
