import axios from "axios";
import UTIL_METHODS from "../utils/UtilMethods";

export default class ApiInstance {
  isLive = true;
  BASE_URI = this.isLive
    ? "https://api.oasesedu.com/api/"
    : "https://oases.tripod.xyz.lk/api/";

  data = async (
    method,
    path,
    data = null,
    uploadOption,
    responseType,
    downloadOption
  ) => {
    let access_token = UTIL_METHODS.getToken();

    if (access_token == null) {
      access_token = "";
    }

    return axios({
      method: method,
      url: this.BASE_URI + path,
      data: data,
      headers: {
        Accept: "application/json",
        Authorization:
          access_token.length > 0 ? `Bearer ${access_token}` : `Bearer`,
      },
      onUploadProgress: uploadOption,
      responseType: responseType,
      downloadOption: downloadOption,
    })
      .then((response) => response)
      .catch((e) => {
        return e.response;
      });
  };

  getAxios = async (path) => {
    return axios.get(path);
  };

  getData = (path, data, token = "") => {
    return this.data("get", path, data, token);
  };

  postData = (path, data, uploadProgress, responseType) => {
    return this.data("post", path, data, uploadProgress, responseType);
  };

  putData = (path, data, token = "") => {
    return this.data("put", path, data, token);
  };

  patchData = (path, data, token = "") => {
    return this.data("patch", path, data, token);
  };

  deleteData = (path, data, token = "") => {
    return this.data("delete", path, data, token);
  };
}
